import React from 'react'

import Layout from '../components/layout'

import IconLink from '../components/iconLink/IconLink'
import tvr from './../images/icons/tvr-img.png'
import lotus from './../images/icons/lotus-img.png'
import alpine from './../images/icons/alpine-img.png'

export default function resources() {
  return (
    <Layout>
      <div className="services resources">
        <div className="header">
          <div className="container">
            <div className="row">
              <div className="columns twelve">
                <h1 className="center-text">Resources</h1>
                <p>Introduction to our story </p>
                <p>lorem ipsum dolor sit amet</p>
              </div>
            </div>
          </div>
        </div>
        <div className="full-primary-light">
          <div className="container" style={{ marginTop: '100px' }}>
            <div className="row" style={{ marginBottom: '20px' }}>
              <div className="four columns">
                <IconLink
                  icon={tvr}
                  title="View TVR Info"
                  link="/resources/tvr"
                />
              </div>
              <div className="four columns">
                <IconLink
                  icon={lotus}
                  title="View Lotus Info"
                  link="/resources/lotus"
                />
              </div>
              <div className="four columns">
                <IconLink
                  icon={alpine}
                  title="View Renault Alpine Info"
                  link="/resources/alpine"
                />
              </div>
            </div>
            <div className="row" style={{ marginBottom: '20px' }}>
              <div className="twelve columns">
                <p>
                  Copy about being specialists in TVR, Lotus and Renault Alpine
                  - but making it clear that you can work on other cars too.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
